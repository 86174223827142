
export const BASE = 8453;

export const addresses = {

  [BASE]: {
    BASE: "0xd07379a755A8f11B57610154861D694b2A0f615a",
    ES_BASE: "0xdE30EF700a3B9Ce0C2b1150D6462613533a5f2A7",
    BLP: "0x6ECCA85569F1101bb54Fcd71d81984084096d837",
    BN_BASE: "0xdE30EF700a3B9Ce0C2b1150D6462613533a5f2A7",
    STAKED_BASE_TRACKER: "0x3bb51E85130c03455B408E77b0aff1FdC669D5aD", 
    STAKED_BLP_TRACKER: "0x73c7ce996503109A111CB71AFD00889390Fe7708", 
    BONUS_BASE_TRACKER: "0x1ef74F173948a0C000caa4bcE876a52FDFaE6949",
    FEE_BASE_TRACKER: "0xaE7093DDc18C1A8ab56CAD2c9d94eeC2DCe29b88", 
    FEE_BLP_TRACKER: "0x772Defa308F27cCF8FDBEDa4860fE617846bc383", 
    // BTC: "0xf1648C50d2863f780c57849D812b4B7686031A3D", // 8 decimals wrapped btc
    ETH: "0x4200000000000000000000000000000000000006", // 18 decimals wrapped eth
    RewardReader: "0xE05986f84B4d74dD13f2743eA751c7E1E83076eb", 
    BlpManager: "0x1142c2dB68E9E87B7358e129A40771dBca0A34da", 
    Router: "0xd72d5Ad987BD875FD43Efee6258129064972Ed74", 
    OrderBook: "0x1821e34C08888309546D156C378CEB848278D352", 
    PositionManager: "0xa3d25D0037E50B6526678dA38c7211FcC519C1f0", 
    FastPriceFeed: "0xA8bfe8Ad36F64aB4F037BBdb5E78507640911463", 
    PositionRouter: "0x11b09dBB0b743E0963c80EE43746e85804aCb79e", 
    PositionExecutorUpKeep: ""
  },
};

export function getAddress(chainId, key) {
  if (!chainId in addresses) {
    throw new Error(`Unknown chain ${chainId}`);
  }
  if (!(key in addresses[chainId])) {
    throw new Error(`Unknown address key ${key}`);
  }
  return addresses[chainId][key];
}
